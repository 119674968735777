.nav{
    position: fixed;
    top : 0;
    width: 100%;
    height :30px;
    z-index : 1;
    padding : 20px;
    display : flex;
    justify-content: space-between;
    align-items: center;
    /*ANIMATION*/
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
.nav__black{
    background-color: #111;
}
.nav__logo{
    position : fixed;
    left: 40px;
    width: 80px;
    object-fit: contain;
}
.nav__avatar{
    position: fixed;
    right: 40px;
    width : 30px;
    object-fit: contain;
}